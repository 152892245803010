<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-card title="Notification илгээх">
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <b-form @submit.stop.prevent="submitFile">
            <b-form-file
              type="file"
              id="file"
              ref="file"
              v-on:change="onChange"
            ></b-form-file>
            <b-button class="mt-3" type="submit" variant="primary"
              >Илгээх</b-button
            >
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "NotificationPush",
  data: function() {
    return {
      file: null,
      isLoading: false,
      jsonData: [],
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readerData(this.file);
    },
    submitFile() {
      //console.log(this.$data.jsonData )
      this.$http
        .post(
          this.$config.API_URL + "AdminWebService/upload_notification_xcl",
          {
            jsonData: JSON.stringify(this.$data.jsonData),
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response)
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    readerData(rawFile) {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.$data.jsonData = results;
          this.isLoading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
  },
};
</script>
